import App from '@/App.vue'
import globalContentDe from '@/assets/data/de/global-content.json'
import globalContentEn from '@/assets/data/en/global-content.json'
import globalContentEs from '@/assets/data/es/global-content.json'
import globalContentFr from '@/assets/data/fr/global-content.json'
import globalContentIt from '@/assets/data/it/global-content.json'
import { useAuthStore } from '@/store/auth'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import mitt from 'mitt'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import '@/assets/sass/app.scss'
import router from '@/router'
import * as Sentry from '@sentry/vue'

import { registerSW } from 'virtual:pwa-register'

registerSW({ immediate: true })

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    emitter: ReturnType<typeof mitt>
    $globalContent: {
      [key: string]: GlobalContent
    }
  }
}

interface DexieFile {
  id?: number
  file?: string | ArrayBuffer | null
  filename?: string
}

declare module 'dexie' {
  interface Dexie {
    files: Dexie.Table<DexieFile, number>
  }
}

const pinia = createPinia()
// const authStore = useAuthStore()
const emitter = mitt()
const app = createApp(App)
if (VueQrcode.name) {
  app.component(VueQrcode.name, VueQrcode)
}
app.config.globalProperties.emitter = emitter

app.config.globalProperties.$globalContent = {
  de: globalContentDe,
  en: globalContentEn,
  es: globalContentEs,
  fr: globalContentFr,
  it: globalContentIt
}

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://cffee4d6f8d051dc27cf66897a26f349@o4507707359035392.ingest.de.sentry.io/4507707367161936',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/platinum-pwa\.t9staging\.co\.uk/, /^https:\/\/portal\.platinumstairlifts\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.MODE
  })
}

app.use(router)

app.use(pinia)
useAuthStore()
  .me()
  .then(() => {
    app.mount('#app')
  })

// Add the error handling for Vite preload errors
window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault()
  window.location.reload()
})
